<template>
  <div>
    <div class="tool">
      <div class="map-change flex-row">
        <div class="map-tool map-tool-1" @click="draw(6)"></div>
        <div class="map-tool map-tool-2" @click="draw(1)"></div>
        <div class="map-tool map-tool-3" @click="draw(2)"></div>
        <div class="map-tool map-tool-4" @click="draw(4)"></div>
        <div class="map-tool map-tool-5" @click="draw(3)"></div>
        <div class="map-tool map-tool-6" @click="draw(5)"></div>
        <div v-for="(map, index) in maps" :key="index" @click="clickMap(map)">
          {{ map.name }}
        </div>
      </div>
    </div>
    <component
      ref="mapRef"
      :is="currentMap.component"
      :api-key="currentMap.apiKey"
      :ayMarker="ayMarker"
    ></component>
    <div></div>
  </div>
</template>

<script>
import BaiduMap from "../components/GpsMap/BaiduMap.vue";
import GoogleMap from "../components/GpsMap/GoogleMap.vue";

export default {
  data() {
    return {
      ayMarker: [], //lat纬度：-90~90，北纬正数，南纬负数  lng经度：-180~180，东经正数，西经负数
      currentMap: {
        name: this.$t("map.baidu"),
        component: BaiduMap,
        apiKey: "your_baidu_map_api_key",
      },
      maps: [
        {
          name: this.$t("map.baidu"),
          component: BaiduMap,
          apiKey: "your_baidu_map_api_key",
        },
        {
          name: this.$t("map.guge"),
          component: GoogleMap,
          apiKey: "your_google_map_api_key",
        },
        // {
        //   name: "Bing Map",
        //   component: BingMap,
        //   apiKey: "your_bing_map_api_key",
        // },
        // {
        //   name: "Tianditu Map",
        //   component: TiandituMap,
        //   apiKey: "your_tianditu_map_api_key",
        // },
      ],
      currentMapIndex: 0,
    };
  },
  created() {
    this.ayMarker = [{ lat: 39.915, lng: 116.404 }];
  },
  methods: {
    draw(drawType) {
      this.$refs["mapRef"].drawModel(drawType);
    },
    clickMap(it) {
      this.currentMap = it;
      // this.ayMarker.splice(0, this.ayMarker.length);
      // this.ayMarker = [{ lat: 39.915, lng: 116.404 }];
    },
  },
};
</script>

<style lang="scss" scoped>
.tool {
  height: 40px;
  line-height: 40px;
  position: relative;
  font-size: 14px;
  .flex-row {
    display: flex;
    flex-direction: row;
  }
  .map-change {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
    div {
      padding: 0 5px;
    }
    .map-tool {
      box-sizing: border-box;
      background: url(../assets/bg_drawing_tool.png) no-repeat 0 0;
      // background-size: 100% 100%;
      width: 50px;
      height: 40px;
    }
    .map-tool-1 {
      background-position: -10px -2px;
    }
    .map-tool-2 {
      background-position: -65px -2px;
    }
    .map-tool-3 {
      background-position: -138px -2px;
    }
    .map-tool-4 {
      background-position: -200px -2px;
    }
    .map-tool-5 {
      background-position: -265px -2px;
    }
    .map-tool-6 {
      background-position: -330px -2px;
    }
  }
}
</style>
